<template>
  <div style="position: relative; background-color: #Fafafa;">
    <v-container fill-height fluid v-show="fundraisers.length === 0" id="loading-page">
      <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
      <div class="text-overline text--disabled">Fetching data...</div>
    </v-container>

    <div v-show="fundraisers.length > 0" style="width: 100%">
      <v-row class="pa-4" id="style-4">
        <v-col cols="12" sm="6" md="4" lg="3" v-for="(fr, index) in fundraisers" :key="index">
          <v-card class="pt-8 pb-4">
            <a v-if="fr.facebook" :href="fr.facebook" target="_blank" style="color: #BF4044; text-decoration: none;">
              <v-avatar width="120" height="120">
                <v-img v-if="fr.img" :src="fr.img" aspect-ratio="1" contain></v-img>
                <v-icon v-if="!fr.img" align="center" size="64" color="#747474">mdi-account-multiple-check</v-icon>
              </v-avatar>
            </a>

            <div v-if="!fr.facebook">
              <v-avatar width="120" height="120">
                <v-img v-if="fr.img" :src="fr.img" aspect-ratio="1" contain></v-img>
                <v-icon v-if="!fr.img" align="center" size="64" color="#747474">mdi-account-multiple-check</v-icon>
              </v-avatar>
            </div>

            <v-card-subtitle class="subtitle-1">
              {{ fr.name }}
            </v-card-subtitle>
            <v-card-text v-show="fr.email || fr.phone">
              <div class="grey--text">
                {{ fr.email }}
              </div>
              <div class="grey--text ms-4">
                {{ fr.phone }}
              </div>
            </v-card-text>

            <v-btn v-if="fr.facebook" icon color="#BF4044">
              <a :href="fr.facebook" target="_blank" style="color: #BF4044; text-decoration: none;"
                ><v-icon>mdi-facebook</v-icon></a
              >
            </v-btn>
            <v-btn v-if="fr.website" icon class="ma-1">
              <a :href="fr.website" target="_blank" style="color: #BF4044; text-decoration: none;"
                ><v-icon>mdi-web</v-icon></a
              >
            </v-btn>
            <!-- <v-btn v-if="fr.email" icon class="ma-1">
              <a :href="fr.email" target="_blank" style="color: #BF4044; text-decoration: none;"
                ><v-icon>mdi-email-outline</v-icon></a
              >
            </v-btn> -->
            <v-btn v-if="fr.twitter" icon class="ma-1">
              <a :href="fr.twitter" target="_blank" style="color: #BF4044; text-decoration: none;"
                ><img :src="require('.././assets/twit.svg')" width="20"
              /></a>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PRF',
  props: {
    fundraisers: Array,
  },
  data() {
    return {
      filteredList: [],
      countries: [],
      selected: '',
    };
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#loading-page {
  padding: 4rem;
  display: block;
  align-items: center;
  * {
    margin-bottom: 1rem;
  }
}
</style>
