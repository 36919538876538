<template>
  <v-app>
    <!-- <div style="background:#ffd924;">
      <marquee-text :duration="45" :repeat="3" class="py-1">
        <span class="ml-2">{{ info }}</span>
      </marquee-text>
    </div> -->
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="auto">
            <v-row align="center">
              <a href="">
                <v-img :width="64" aspect-ratio="1" :src="require('./assets/mod_logo.png')" class="mr-4" />
              </a>
              <v-img :width="96" aspect-ratio="1" :src="require('./assets/prf/prsf_text.png')" contain />
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="center" style="color: #bf4044">
          <span class="text-h5" style="font-weight: bolder">Official PRF Fundraisers</span>
        </v-row>

        <!-- SELECT REGION -->
        <div class="mt-10" v-show="!prf">
          <v-toolbar class="mt-4" color="#BF4044" fixed>
            <v-btn icon @click="updateSelection('all')">
              <v-icon color="white">mdi-view-parallel</v-icon>
            </v-btn>
            <v-btn icon @click="updateSelection('asia')">
              <img :src="require('./assets/asia.svg')" width="32" />
            </v-btn>
            <v-btn icon @click="updateSelection('north america')">
              <img :src="require('./assets/north_america.svg')" width="32" />
            </v-btn>
            <v-btn icon @click="updateSelection('australia')">
              <img :src="require('./assets/aussi.svg')" width="32" />
            </v-btn>
            <v-btn icon @click="updateSelection('europe')">
              <img :src="require('./assets/europe.svg')" width="32" />
            </v-btn>
            <v-btn icon @click="updateSelection('global')">
              <v-icon color="white">mdi-earth</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!map"
              @click="
                map = !map;
                refreshMap();
              "
              icon
            >
              <v-icon color="white"> mdi-map-legend </v-icon>
            </v-btn>
            <v-btn v-if="map" @click="map = !map" icon>
              <v-icon color="white"> mdi-format-list-bulleted-square </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card style="height: 70vh; overflow: hidden; overflow-y: auto">
            <v-toolbar v-show="map" class="text-h6 text-capitalize" style="position: sticky; top: 0; z-index: 999">
              {{ selected }}
            </v-toolbar>
            <Map
              v-if="map"
              ref="updateMap"
              :selected="selected"
              :locations="locations"
              :mapStyle="{ height: '86%' }"
              @clicked="showRegionalFundraisers"
            />

            <v-subheader style="position: sticky; top: 0; z-index: 1" v-show="!map" class="overline"
              >{{ this.selected }}: {{ filteredList.length }}</v-subheader
            >
            <v-tabs v-show="!map" show-arrows style="position: sticky; top: 0; z-index: 1">
              <v-tab @click="updateFrListByCountry('all')">all</v-tab>
              <v-tab
                v-show="countries.length > 1"
                v-for="(country, index) in countries"
                :key="index"
                @click="updateFrListByCountry(country)"
                >{{ country }}</v-tab
              >
            </v-tabs>

            <v-row v-show="!map" class="ma-4">
              <v-col cols="12" sm="6" md="4" lg="3" v-for="(fr, index) in filteredList" :key="index">
                <Fundraiser :fundraiser="fr" />
              </v-col>
            </v-row>
          </v-card>
        </div>

        <div class="mt-10" v-show="prf">
          <v-row justify="space-between" class="pb-0">
            <v-col cols="12" xs="12" sm="12" md="4" lg="3" class="pb-0">
              <v-btn
                block
                elevation="2"
                large
                color="#BF4044"
                href="https://drive.google.com/drive/u/0/folders/1ukuHOSoLbKUHpzZOFHxSTYNka5vc5HOt"
                target="_blank"
              >
                <v-icon color="white" class="mr-2">mdi-google-drive</v-icon>
                <div style="color: white">
                  Promotional Materials
                </div>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="3" class="pb-0 ">
              <!-- <v-select
                prepend-icon="mdi-map"
                :items="prfCountries"
                color="#BF4044"
                label="All"
                v-model="prfSelectedCountry"
                solo
              ></v-select> -->
              <v-select
                color="#BF4044"
                v-model="prfSelectedCountry"
                :items="prfCountries"
                :menu-props="{ maxHeight: '400' }"
                label="Select Country"
                :hint="`Total Fundraiser(s):${prfFrList.length}`"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
          <!-- <v-row justify="flex-end">
            <v-col cols="12" sm="12" style="text-align: end;" class="pt-0 pb-0">
              <span class="text-caption grey--text">Select Country • Total Fundraiser(s):{{ prfFrList.length }}</span>
            </v-col>
          </v-row> -->

          <v-row justify="start">
            <v-col cols="12" sm="12">
              <v-card style="background-color: #Fafafa; height: 70vh; width: 100%; overflow: hidden; overflow-y: auto">
                <PRF :fundraisers="prfFrList" />
              </v-card>
            </v-col>
          </v-row>
        </div>

        <!-- <v-btn
          icon
          rounded
          style="background-color: #BF4044"
          bottom
          right
          absolute
          dark
          @click="showFaq = !showFaq"
        >
          <v-icon>mdi-help</v-icon>
        </v-btn> -->

        <!-- DIALOG -->
        <v-dialog style="z-index: 9999" v-show="showModal" v-model="showModal" max-width="60vh">
          <v-card class="pb-2">
            <v-toolbar
              class="text-h6 text-capitalize"
              style="position: sticky; top: 0; z-index: 999"
              color="#BF4044"
              dark
            >
              <v-icon class="pr-4" aria-hidden="false" medium>mdi-account-multiple-check</v-icon>
              {{ title }}
              <v-chip class="ml-2" outlined pill medium>{{ filteredList.length }}</v-chip>

              <v-spacer></v-spacer>
              <v-btn class="mx-2" fab small @click="showModal = !showModal" color="#BF4044" depressed>
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
            </v-toolbar>
            <v-row class="ma-4">
              <v-col cols="12" md="6" v-for="(fr, index) in filteredList" :key="index">
                <Fundraiser :fundraiser="fr" />
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>

        <!-- DISCLAIMER -->
        <v-dialog style="z-index: 9999" v-show="showDisclaimer" v-model="showDisclaimer" max-width="60vh">
          <v-card>
            <v-toolbar
              class="text-h6 text-capitalize"
              style="position: sticky; top: 0; z-index: 999"
              color="#BF4044"
              dark
            >
              <v-icon class="pr-4" aria-hidden="false" large>mdi-information-outline</v-icon>
              {{ infoTitle }}
              <v-spacer></v-spacer>
              <v-btn class="mx-2" fab small @click="showDisclaimer = !showDisclaimer" color="#BF4044" depressed>
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pa-4" style="text-align: justify" v-html="infoBody">
              {{ infoBody }}
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog style="z-index: 9999" v-show="showFaq" v-model="showFaq" max-width="80vh" persistent>
          <v-card height="80vh">
            <v-toolbar
              class="text-h6 text-capitalize"
              style="position: sticky; top: 0; z-index: 999"
              color="#BF4044"
              dark
            >
              <v-icon class="pr-4" aria-hidden="false" medium>mdi-help-circle</v-icon>
              FAQ
              <v-spacer></v-spacer>
              <v-btn
                class="mx-2"
                fab
                small
                @click="
                  showFaq = !showFaq;
                  panel = [];
                "
                color="#BF4044"
                depressed
              >
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pa-4" style="text-align: justify">
              <v-expansion-panels v-model="panel">
                <v-expansion-panel v-for="(item, i) in faqs" :key="i">
                  <v-expansion-panel-header class="text-subtitle-1">
                    {{ item.question }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="text--lighten-1 pt-4">
                    {{ item.answer }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- FOOTER -->
        <v-row justify="space-between" class="pt-2">
          <v-col cols="auto" class="bottom-links" justify="left">
            <span>PRF © 2022. All rights reserved. </span> |
            <span class="clickable" @click="showBottomLink('disclaimer')">Disclaimer</span>
            |
            <span class="clickable" @click="showBottomLink('privacy policy')"> Privacy Policy</span>
            |
            <span class="clickable" @click="showBottomLink('copyright')"> Copyright</span>
          </v-col>
          <v-col cols="auto" class="bottom-links" justify="center">
            <!-- <a href="https://www.facebook.com/crph.ofp.official" class="footer-icon" target="_blank">
              <v-icon color="#BF4044">mdi-google-drive</v-icon>
            </a> -->
            <a href="https://www.facebook.com/prf4pdf" class="footer-icon" target="_blank">
              <v-icon color="#BF4044">mdi-facebook</v-icon>
            </a>
            <!-- <a href="https://twitter.com/CrphMyanmar" class="footer-icon" target="_blank">
              <v-icon color="#BF4044">mdi-twitter</v-icon>
            </a>
            <a href="https://www.youtube.com/channel/UCm68ovegWwcyY9ESFZayVtg" class="footer-icon" target="_blank">
              <v-icon color="#BF4044">mdi-youtube</v-icon>
            </a> -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Fundraiser from './components/Fundraiser.vue';
// import Counter from './components/Counter.vue'
import Map from './components/Map.vue';
import PRF from './components/PRF.vue';
import axios from 'axios';
// import MarqueeText from 'vue-marquee-text-component'

export default {
  name: 'App',
  components: {
    Fundraiser,
    // Counter,
    Map,
    PRF,
    // MarqueeText
  },
  data() {
    return {
      apiKey: 'keySLF7Ph3gg0FEuk',
      base: 'app4TxrkozxBgbBS1',
      frList: [],
      map: true,
      prf: true,
      selected: 'all',
      counterValue: Object,
      locations: [],
      countries: [],
      filteredList: [],
      links: [],
      title: '',
      showModal: false,
      showDisclaimer: false,
      infoTitle: '',
      infoBody: '',
      showFaq: false,
      faqs: [],
      panel: [],
      info:
        'NUG အစိုးရ၏ အင်တာနက်စာမျက်နှာအချို့အား ပြင်ဆင်ထိန်းသိမ်းခြင်းလုပ်ငန်းများကိုပြုလုပ်နေပါသောကြောင့် ခေတ္တဝင်ကြည့်၍ရနိုင်မည်မဟုတ်ကြောင်း အသိပေးအပ်ပါသည်။',
      showFrList: false,
      campaign_data: [],
      campaign_counter: [],
      // PRF
      prfFrList: [],
      prfCountries: [],
      prfSelectedCountry: '',
      prfSelectHint: '',
      ipurl: 'https://api.ipify.org/?format=json',
      userip: '',
    };
  },
  watch: {
    selected() {
      this.updateCountries();
    },
    map() {
      this.updateCountries();
    },
    prfSelectedCountry() {
      setTimeout(() => {
        this.filterByCountry(this.prfSelectedCountry);
      }, 1000);
    },
  },
  mounted() {
    this.getData();
    this.getLocations();
    this.getLinks();

    this.getIPInfo();
  },
  methods: {
    getLocations: function() {
      axios({
        url: `https://api.airtable.com/v0/app4TxrkozxBgbBS1/locations`,
        headers: {
          Authorization: `Bearer ${this.apiKey}`,
        },
      }).then((res) => {
        res.data.records.forEach((record) => {
          this.locations.push(record.fields);
        });
      });
    },

    getLinks: function() {
      axios({
        url: `https://api.airtable.com/v0/app4TxrkozxBgbBS1/links`,
        headers: {
          Authorization: `Bearer ${this.apiKey}`,
        },
      }).then((res) => {
        res.data.records.forEach((record) => {
          this.links.push(record.fields);
        });
      });
    },

    getData: function() {
      let results = [];
      let baseURL = 'https://api.airtable.com/v0/app4TxrkozxBgbBS1/groups';
      let headers = { authorization: 'Bearer ' + this.apiKey };
      let params = { pageSize: 100 };

      this.frList = [];

      axios({
        baseURL: baseURL,
        headers: headers,
        params: params,
      })
        .then((res) => {
          results.push(...res.data.records);
          params.offset = res.data.offset;
          const axcall = () => {
            axios({
              baseURL: baseURL,
              headers: headers,
              params: params,
            })
              .then((res) => {
                results.push(...res.data.records);
                params.offset = res.data.offset;

                if (res.data.offset !== undefined) {
                  return axcall();
                } else {
                  // after all calls ends
                  results.forEach((i) => this.frList.push(i.fields));

                  this.frList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
                  this.updateCountries();
                }
              })
              .catch((e) => console.log(e));
          };
          axcall();
        })
        .catch((e) => console.log(e));
    },

    getFaq: function() {
      axios({
        url: `https://api.airtable.com/v0/app4TxrkozxBgbBS1/faq`,
        headers: {
          Authorization: `Bearer ${this.apiKey}`,
        },
      }).then((res) => {
        res.data.records.forEach((record) => {
          this.faqs.push(record.fields);
        });
      });
    },

    get5MData: function() {
      // 5m_campaign
      let tmp = [];
      axios({
        url: `https://api.airtable.com/v0/app4TxrkozxBgbBS1/5m_campaign`,
        // url: `https://api.airtable.com/v0/app4TxrkozxBgbBS1/test`,
        headers: {
          Authorization: `Bearer ${this.apiKey}`,
        },
      }).then((res) => {
        res.data.records.forEach((record) => {
          tmp.push({
            country: record.fields.country,
            tickets_sold: record.fields.tickets_sold,
          });
        });
      });
      // console.log(tmp);
      this.campaign_data = tmp;
    },

    get5MCounter: function() {
      // 5m_campaign
      axios({
        url: `https://api.airtable.com/v0/app4TxrkozxBgbBS1/5m_counter`,
        headers: {
          Authorization: `Bearer ${this.apiKey}`,
        },
      }).then((res) => {
        res.data.records.forEach((record) => {
          this.campaign_counter.push(record.fields);
        });
      });
    },

    getIPInfo: function() {
      axios(this.ipurl)
        .then((response) => {
          this.userip = response.data.ip;
          this.getIpAddressData(this.userip);
        })
        .catch((err) => {
          console.log('Error IPDATA FETCH', err);
        });
    },

    getIpAddressData(ip) {
      let ipapi = `https://ipapi.co/${ip}/json`;

      axios(ipapi)
        .then((response) => {
          if (response.data.error) {
            this.prfSelectedCountry = 'All';
          } else {
            this.prfSelectedCountry = response.data.country_name;
          }
        })
        .catch((err) => {
          console.log('There is an error fetching ipdata.', err);
          return false;
        })
        .finally(() => {
          // this.filterByCountry(this.prfSelectedCountry);
        });
    },

    updateSelection(value) {
      this.selected = value;
      this.updateCountries();
    },

    updateCountries() {
      this.filteredList = [];
      if (this.selected === 'all') {
        this.frList.forEach((fr) => this.filteredList.push(fr));
      } else {
        this.frList
          .filter((fr) => fr.continent && fr.continent.toLowerCase() === this.selected)
          .forEach((fr) => this.filteredList.push(fr));
      }
      this.countries = [...new Set(this.filteredList.map((item) => item.country))];
      this.countries.sort();

      this.showModal = this.selected === 'global' && this.map;
      this.title = this.selected;

      // update PRF
      this.prfInit();
    },

    updateFrList(value) {
      this.filteredList = [];
      if (this.selected != 'all')
        this.frList.filter((fr) => fr.continent && fr.continent.toLowerCase() === this.selected);

      if (value != 'all') {
        this.frList
          .filter((fr) => fr.state && fr.state.toLowerCase() === value.toLowerCase())
          .forEach((fr) => this.filteredList.push(fr));
      } else {
        this.frList.forEach((fr) => this.filteredList.push(fr));
      }
    },

    updateFrListByCountry(value) {
      this.filteredList = [];

      if (value != 'all') {
        this.frList
          .filter((fr) => fr.country && fr.country.toLowerCase() === value.toLowerCase())
          .forEach((fr) => this.filteredList.push(fr));
      } else {
        this.selected === 'all'
          ? this.frList.forEach((fr) => this.filteredList.push(fr))
          : this.frList
              .filter((fr) => fr.continent && fr.continent.toLowerCase() === this.selected)
              .forEach((fr) => this.filteredList.push(fr));
      }
    },

    showRegionalFundraisers(value) {
      this.updateFrList(value);
      //bring up dialog box
      this.title = `${this.filteredList[0].continent}: ${value}`;
      this.showModal = true;
    },

    refreshMap() {
      // console.log("app");
      // this.$refs.updateMap.updateLayers();
      this.selected = 'all';
      this.getLocations();
    },

    showBottomLink(value) {
      this.showDisclaimer = true;
      this.infoTitle = value;
      this.infoBody = this.links.find((i) => i.link === value).value;
    },

    // PRF
    prfInit() {
      this.frList.filter((fr) => fr.prf).forEach((fr) => this.prfFrList.push(fr));
      this.prfCountries = [...new Set(this.prfFrList.map((item) => item.country))];
      this.prfCountries.push(`All`);
      this.prfCountries.sort();
      this.prfFrList.sort(() => 0.5 - Math.random());
      //
    },

    filterByCountry(selected) {
      this.prfFrList = [];
      selected = selected.split('(')[0].trim();
      let tmp = Object.assign(
        [],
        this.frList.filter((fr) => fr.prf)
      );

      if (selected !== 'All') {
        this.prfFrList = tmp.filter((fr) => fr.country.toLowerCase() === selected.toLowerCase());
      } else {
        this.prfFrList = tmp;
      }

      this.prfFrList.sort(() => 0.5 - Math.random());
      this.prfSelectHint = `Total Fundraiser(s): ${this.prfFrList.length}`;
    },
  },
  created() {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

.footer-icon {
  text-decoration: none;
  padding-left: 0.5rem;
}

v-icon {
  color: #bf4044;
}

.domain {
  unicode-bidi: bidi-override;
  direction: rtl;
}

.bottom-links {
  font-size: 0.76rem;
  font-style: normal;
  color: #bf4044;
}

.clickable {
  cursor: pointer;
}
</style>
