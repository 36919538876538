<template>
  <div id="map" class="my-4" :style="mapStyle" @click="onClick($event.target.alt)"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  name: "CustomPath",
  components: {
  },
  props: {
    locations: Array,
    mapStyle: { height: '30vh' },
    selected: String
  },
  data() {
    return {
      map: null,
      layers: null,
      overlay: {},
      markers: [],
      continents: [],
    };
  },
  watch:{
    locations() { this.addMarkers() },
    selected() { this.updateLayers() }
  },
  mounted(){
    this.initMap();
  },
  methods:{
    initMap() {
      this.map = L.map('map', { zoomControl: false, scrollWheelZoom: false }).setView([35.26955358, 36.09373569], 3);
      
      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png', {
        maxZoom: 6,
        minZoom: 2,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
      }).addTo(this.map);
    },

    addMarkers(){
      this.continents = [...new Set(this.locations.map(item => item.continent.toLowerCase()))];
      const markerIcon = L.icon({
        iconUrl: require('../assets/marker.png'),
        iconSize:     [20, 20], // size of the icon
        iconAnchor:   [12, 12], // point of the icon which will correspond to marker's location
      });

      for(let i=0; i<this.continents.length; i++){
        let layerGroup = L.layerGroup().addTo(this.map);
        
        let tmp = this.locations.filter(location => location.continent.toLowerCase() === this.continents[i]);
        tmp.forEach(location => {
          let marker = this.createMarker(location, markerIcon).addTo(this.map);
          layerGroup.addLayer(marker);
        });

        this.overlay[this.continents[i].toString()] = layerGroup;
      }

      L.control.layers(null, this.overlay).addTo(this.map);
      this.showAllMarkers();
    },

    createMarker(location, markerIcon){
      // let tooltip = (location.city) ? `${location.city}, ${location.country}` : `${location.country}`;
      let tooltip = '';
      tooltip += (location.city) ? `${location.city}, ` : ``;
      tooltip += `${location.state}, ${location.country}`;
      return L.marker([location.lat, location.lng], { 
          icon: markerIcon, 
          alt: (location.state) ? location.state : location.country
        }).bindTooltip(tooltip.toUpperCase(), { permanent: false, direction: 'bottom', offset: L.point({ x: 0, y: 0 }) });
    },

    onClick (value) {
      if(value && value !== "map") this.$emit('clicked', value);
    },

    hideAllLayers(){
      for (let key in this.overlay) {
        this.map.removeLayer(this.overlay[key]);
      }
    },

    showAllLayers(){
      for (let key in this.overlay) {
        this.map.addLayer(this.overlay[key]);
      }
    },

    updateLayers(){
      // console.log("map")
      this.hideAllLayers();

      switch(this.selected){
        case 'global':
        case 'all':
          this.showAllLayers();
          break;

        default:
          this.map.addLayer(this.overlay[this.selected]);
      }

      this.showAllMarkers();
    },

    showAllMarkers() {
      var bounds = L.latLngBounds();
      this.map.eachLayer( function(layer) {
        if(layer instanceof L.Marker) {
          bounds.extend([layer.getLatLng().lat, layer.getLatLng().lng]);
        }
      });
      this.map.fitBounds(bounds);
      this.map.invalidateSize();
    },

    refreshMap(){
      this.showAllMarkers();
    },

    updateLocations(value){
      this.locations = value;
    }

  },
};
</script>

<style>
.leaflet-control-layers {
  visibility: hidden !important;
}
</style>