<template>
  <v-card class="pt-8 pb-4">
    <a v-if="fundraiser.facebook" :href="fundraiser.facebook" target="_blank">
      <v-img v-if="fundraiser.img" :src="fundraiser.img" aspect-ratio="1" height="120" contain></v-img>
      <v-avatar v-if="!fundraiser.img" width="200" height="120">
        <v-icon align="center" size="64" color="#747474">mdi-account-multiple-check</v-icon>
      </v-avatar>
    </a>

    <div v-if="!fundraiser.facebook">
      <v-img v-if="fundraiser.img" :src="fundraiser.img" aspect-ratio="1" height="120" contain></v-img>
      <v-avatar v-if="!fundraiser.img" width="200" height="120">
        <v-icon align="center" size="64" color="#747474">mdi-account-multiple-check</v-icon>
      </v-avatar>
    </div>

    <v-card-subtitle class="subtitle-1 pt-8"> {{ fundraiser.flag }} {{ fundraiser.name }} </v-card-subtitle>

    <v-card-text>
      <div>
        {{ fundraiser.city }} <span v-if="fundraiser.city">|</span> {{ fundraiser.state }} | {{ fundraiser.country }}
      </div>
      <span v-if="fundraiser.phone">{{ fundraiser.phone }}</span>
      <span v-if="fundraiser.email" class="text-lowercase">{{ fundraiser.email }}</span>
      <br />
      <!-- <p class="overline mb-4">{{ fundraiser.website }}</p> -->
      <v-btn v-if="fundraiser.facebook" icon color="#BF4044">
        <a :href="fundraiser.facebook" target="_blank"
          ><v-icon style="color: #BF4044; text-decoration: none;">mdi-facebook</v-icon></a
        >
      </v-btn>
      <v-btn v-if="fundraiser.website" icon>
        <a :href="fundraiser.website" target="_blank"
          ><v-icon style="color: #BF4044; text-decoration: none;">mdi-web</v-icon></a
        >
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Fundraiser",
  props: {
    fundraiser: Object,
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

v-btn {
  color: #bf4044;
}
</style>
