import { render, staticRenderFns } from "./Fundraiser.vue?vue&type=template&id=4544c00b&scoped=true&"
import script from "./Fundraiser.vue?vue&type=script&lang=js&"
export * from "./Fundraiser.vue?vue&type=script&lang=js&"
import style0 from "./Fundraiser.vue?vue&type=style&index=0&id=4544c00b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4544c00b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VBtn,VCard,VCardSubtitle,VCardText,VIcon,VImg})
